///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   08/02/2014
/// @modified:  09/02/2015
/// @name:      Media Query Max 768px width
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

@media (max-width: 768px){

  .navbar-brand .job{
    text-align: left;
    font-size: 1.8rem;
    line-height: 1.8rem;
    display: inline;
  }

  .navbar-brand br{
    display: block;
  }

  .navbar-brand{
    display: block;
    width: 70%;
  }

  #main-nav .navbar-brand{
    color: @top-nav-brand-color-mid;
  }

  #main-nav{
    background: @top-nav-background-mid;
    border-bottom: .1rem solid @top-nav-border-mid;
  }

  #main-nav .container{
    padding: 1rem 2rem;
  }

  .navbar-brand.scrollto > span.job{
    padding-top: .8rem;
  }

  #main-nav .nav a,
  #main-nav .nav a:active,
  #main-nav .nav a:hover{
    color: @top-nav-anchor-mid-color;
    line-height: 4.2rem;
    text-align: center;
  }

  #main-nav .nav a:before{
    display:none;
  }

  #main-nav .nav a:hover,
  #main-nav .nav li.active a{
    box-shadow: 0 0 0 .1rem;
  }


  .jumbotron .right-col{
    min-height: 8rem;
  }

  .jumbotron .right-col p{
    font-size: 2rem;
    line-height: 1.8rem;
  }

  #main-footer ul.footer-nav{
    margin-bottom: 2.4rem;
  }

  #main-footer .left-col,
  #main-footer .right-col{
    text-align: center;
  }

  .modal-dialog{
    max-width: 80%;
  }

  .modal-body .left-col,
  .modal-body .right-col{
    width: 100%;
  }

  h2.section-title{
    font-size: 4.2rem;
    line-height: 5.6rem;
  }

}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   20/01/2014
/// @modified:  09/02/2015
/// @name:      Contact section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

#contact {
  background: transparent;
  color: @contact-color;
  padding: 0;
  margin-bottom: 5rem;
}

#contact-title-header {
  padding-top: 4rem;
  padding-bottom: .5rem;
  margin-bottom: 4rem;
  background: @contact-header-background;
}

#contact .section-title {
  color: @contact-title-color;
  margin-bottom: 8.4rem;
}

#contact .section-title [class*=fa] {
  color: @contact-title-icon;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform:rotate(-15deg);
  opacity: .6;
  filter: alpha(opacity=60);
}


#contact .contact-info p,
#contact .contact-info address {
  margin:0 0 .3rem;
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 400;
}


#contact .socials {
  margin: 0;
  padding: 0;
  margin-bottom: 2.4rem;
}

#contact .socials li {
  display: inline-block;
  margin: 0 1.5rem 1.5rem 0;
}

#contact .socials li a {
  display:inline-block;
  width: 4.8rem;
  height: 4.8rem;
  font-size: 3.2rem;
  line-height: 4.8rem;
  text-align: center;
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: @contact-box-anchor;
  .custom-transition;
}


#contact .socials li a:hover {
  padding: 0;
  color: @contact-box-anchor-hover;
  text-decoration: none;
  .custom-transition;
}


#contact-form .form-group label {
  display: none;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

#contact-form.no-placeholder .form-group label {
  display: block;
}

#contact-form .form-control {
  background: @contact-form-background;
  border: none;
  border-radius: .1rem;
  outline: none;
  box-shadow: none;
  margin-bottom: 3rem;
  height: 6.8rem;
  font-size: 3rem;
  line-height: 4.8rem;
  font-weight: 400;
  color: @contact-form-color;
  padding-left: 6.4rem;
}

#contact-form .form-group{
  position: relative;
}

#contact-form .form-group [class*="fa-"],
#contact-form .form-group [class*="brk-"] {
  display: block;
  width: 6.4rem;
  position: absolute;
  top: 0;
  color: @contact-form-icon-color;
  left: .5rem;
  font-size: 3.2rem;
  line-height: 6.8rem;
  text-align: center;
  font-weight: 400;

}

#contact-form.no-placeholder .form-group [class*="fa-"],
#contact-form.no-placeholder .form-group [class*="brk-"] {
  top: 3rem;
}

#contact-form textarea.form-control {
  height: auto;
  max-width: 100%;
  min-width: 100%;
  font-size: 3rem;
  line-height: 4.2rem;
  padding-top: 1.4rem;
}

#contact-form .form-control:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

#contact-form .form-control::-webkit-input-placeholder,
#contact-form .form-control:-moz-placeholder,
#contact-form .form-control::-moz-placeholder,
#contact-form .form-control:-ms-input-placeholder {
  color: @contact-form-placeholder;
  opacity: 1;
}

#contact-form .error-message {
  background: @contact-form-error-background;
  color: @contact-form-error-color;
  padding: 1rem 2rem;
  position: absolute;
  top: -1rem;
  right: -1rem;
  font-size: 1.6rem;
  font-weight: 400;
}

.contact-box {
  padding-left: 1rem;
  color: @contact-box-color;
  background-color: @contact-box-background;
}

.contact-box.fb {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.contact-box.alternative {
  color: @contact-box-color-alternative;
  background-color: @contact-box-background-alternative;
}

.contact-link {
  color: @contact-box-anchor;
  transition:all 0.2s ease-out;
}

.contact-link:hover {
  color: @contact-box-anchor-hover;
  transition:all 0.2s ease-out;
}

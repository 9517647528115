///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   08/02/2014
/// @modified:  09/02/2015
/// @name:      Media Query Max 1025px width
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

@media (max-width: 1025px){

  .jumbotron .message-box{
    height: 35rem;
    width: 60%;
  }

  .jumbotron .left-col p{
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .jumbotron .left-col p span{
    font-size: 3.6rem;
  }

  .jumbotron .right-col p{
    font-size: 4rem;
    line-height: 3.7rem;
  }

  .navbar-brand br{
    display: none !important;
  }

}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   08/02/2014
/// @modified:  15/11/2016
/// @name:      Footer section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
#main-footer {
  background: @footer-background;
  color: @footer-color;
  padding: 3rem 0;
}

#main-footer .small-logo {
  padding: 0;
  margin: 0;
  margin-bottom: 2.4rem;
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: normal;
  color: @footer-logo-color;
}

#main-footer .small-logo span {
  padding: 0;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-transform: none;
}


#main-footer ul.footer-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 2.4rem;
}

#main-footer ul.footer-nav li{
  display: inline-block;
  padding: 0 1.5rem;
  line-height: 2.4rem;
  border-right: .1rem solid @footer-li-border;
}

#main-footer ul.footer-nav li:first-child{
  padding-left: 0;
}

#main-footer ul.footer-nav li:last-child{
  border-right: none;
}

#main-footer a{
  color: @footer-anchor;
  text-decoration: none;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .02rem;
  padding: 0;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}

#main-footer a:hover,
#main-footer a:active{
  color: @footer-anchor-hover;
  text-decoration: none;
}

#main-footer p{
  color: @footer-p;
  font-size: 1.4rem;
  line-height: 2.5rem;
  font-weight: 400;
}

#main-footer .right-col{
  text-align: right;
}

#main-footer .right-col h2{
  text-transform: none;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin: 0 0 2.4rem;
}

#main-footer .right-col p{
  font-size: 1.5rem;
  line-height: 2.4rem;
  font-weight: 400;
}

#main-footer .creative-commons-info{
  font-size: 4rem;
  i {
    font-size: 5rem;
    vertical-align: text-top;
  }
}

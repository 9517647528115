.brk-xs {
  font-size: 100%;
}

.brk-fa {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -25%;
  font-size: 100%;
}


.brk-sm {
  font-size: 130%
}

.brk-md {
  font-size: 180%;
}

.brk-lg {
  font-size: 200%;
}

.brk-2x {
  font-size: 250%;
}

.brk-3x {
  font-size: 350%;
}

.brk-4x {
  font-size: 450%;
}

.brk-5x {
  font-size: 550%;
}

.brk-3d {
  text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

.brk-inverse {
  color: #ffffff;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   31/08/2014
/// @modified:  09/02/2015
/// @name:      Skills section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

#skills{
  padding-top: 8rem;
  color: @skills-color;
  background: @skills-background-color;
}

.skills .col-sm-3{
  margin-bottom: 8rem;
}

#skills.cuda-section{
  padding-bottom: 4rem;
}

.chart{
  position: relative;
  display: inline-block;
  width: 17rem;
  height: 17rem;
  margin-bottom: 5rem;
  text-align: center;
}

.chart canvas{
  position: absolute;
  top: 0;
  left: 0;
}

#skills .percent{
  display: inline-block;
  line-height: 17rem;
  font-size: 5rem;
  font-weight: 600;
  color: @skills-percent-color;
  z-index: 2;
}

#skills .percent:after{
  content: '%';
  color: @skills-percent-color;
  margin-left: .1em;
  font-size: 4rem;
}

#skills .skills h2{
  text-transform: capitalize;
  margin: 0 0 8rem;
  color: @skills-h2-color;
  font-size: 2.4rem;
  font-weight: 400;
}

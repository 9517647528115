/*/////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    Ricardo Barros
/// @adapter:   José Antonio Yáñez Jiménez
/// @created:   24/08/2014
/// @modified:  15/11/2016
/// @name:      cc-icons
/// @version:   1.2.1
/// @site:      https://github.com/cc-icons/cc-icons
///
///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////*/


@font-face {
  font-family: 'cc-icons';
  src: url('/fonts/cc-icons.eot');
  src: url('/fonts/cc-icons.woff') format('woff'),
       url('/fonts/cc-icons.ttf') format('truetype'),
       url('/fonts/cc-icons.otf') format('opentype'),
       url('/fonts/cc-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.cc
{
 -moz-font-feature-settings: normal;
 -moz-font-language-override: normal;
 display: inline-block;
 font-family: cc-icons;
 font-size: inherit;
 font-size-adjust: none;
 font-stretch: normal;
 font-style: normal;
 font-variant: normal;
 font-weight: normal;
 line-height: 1;
 text-rendering: auto;
}

/* makes the font 33% larger relative to the icon container */
.cc-lg
{
 font-size: 2.33333333em;
 line-height: 0.75em;
 vertical-align: -15%;
}
.cc-2x
{
 font-size: 3em;
}
.cc-3x
{
 font-size: 4em;
}
.cc-4x
{
 font-size: 5em;
}
.cc-5x
{
 font-size: 6em;
}
.cc-fw
{
 width: 1.28571429em;
 text-align: center;
}
.cc-ul
{
 padding-left: 0;
 margin-left: 2.14285714em;
 list-style-type: none;
}
.cc-ul > li
{
 position: relative;
}
.cc-li
{
 position: absolute;
 left: -2.14285714em;
 width: 2.14285714em;
 top: 0.14285714em;
 text-align: center;
}
.cc-li.cc-lg
{
 left: -1.85714286em;
}
.cc-border
{
 padding: .2em .25em .15em;
 border: solid 0.08em #eeeeee;
 border-radius: .1em;
}
.pull-right
{
 float: right;
}
.pull-left
{
 float: left;
}
.cc.pull-left
{
 margin-right: .3em;
}
.cc.pull-right
{
 margin-left: .3em;
}
.cc-spin
{
 -webkit-animation: cc-spin 2s infinite linear;
 animation: cc-spin 2s infinite linear;
}
@-webkit-keyframes cc-spin
{
 0%
 {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
 }
 100%
 {
  -webkit-transform: rotate(359deg);
  transform: rotate(359deg);
 }
}
@keyframes cc-spin
{
 0%
 {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
 }
 100%
 {
  -webkit-transform: rotate(359deg);
  transform: rotate(359deg);
 }
}
.cc-rotate-90
{
 filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
 -webkit-transform: rotate(90deg);
 -ms-transform: rotate(90deg);
 transform: rotate(90deg);
}
.cc-rotate-180
{
 filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
 -webkit-transform: rotate(180deg);
 -ms-transform: rotate(180deg);
 transform: rotate(180deg);
}
.cc-rotate-270
{
 filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
 -webkit-transform: rotate(270deg);
 -ms-transform: rotate(270deg);
 transform: rotate(270deg);
}
.cc-flip-horizontal
{
 filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
 -webkit-transform: scale(-1, 1);
 -ms-transform: scale(-1, 1);
 transform: scale(-1, 1);
}
.cc-flip-vertical
{
 filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
 -webkit-transform: scale(1, -1);
 -ms-transform: scale(1, -1);
 transform: scale(1, -1);
}
:root .cc-rotate-90,
:root .cc-rotate-180,
:root .cc-rotate-270,
:root .cc-flip-horizontal,
:root .cc-flip-vertical
{
 filter: none;
}
.cc-stack
{
 position: relative;
 display: inline-block;
 width: 2em;
 height: 2em;
 line-height: 2em;
 vertical-align: middle;
}
.cc-stack-1x,
.cc-stack-2x
{
 position: absolute;
 left: 0;
 width: 100%;
 text-align: center;
}
.cc-stack-1x
{
 line-height: inherit;
}
.cc-stack-2x
{
 font-size: 2em;
}
.cc-inverse
{
 color: #ffffff;
}

/*===========================
Round Icons
===========================*/

/*Share Alike*/
.cc-sa:before
{
 content: "\0061";
}
/*Attribution*/
.cc-by:before
{
 content: "\0062";
}
/* CC */
.cc-cc:before
{
 content: "\0063";
}

/* Non Derivative */
.cc-nd:before
{
 content: "\0064";
}

/* Non Commercial Euro */
.cc-nc-eu:before
{
 content: "\0065";
}

/* Non Commercial Japan Yen */
.cc-nc-jp:before
{
 content: "\006A";
}

/* Sampling */
.cc-sampling:before
{
 content: "\006D";
}

/* Non Commercial */
.cc-nc:before
{
 content: "\006E";
}

/* Zero */
.cc-zero:before
{
 content: "\006F";
}

/* Public Domain */
.cc-pd:before
{
 content: "\0070";
}

/* Public Domain Alternative */
.cc-pd-alt:before
{
 content: "\0071";
}

.cc-remix:before
{
 content: "\0072";
}
/* Share*/
.cc-share:before
{
 content: "\0073";
}

/*===========================
Banners
===========================*/

/* Some Rights Reserved */
.cc-srr:before
{
 content: "\0041";
}

.cc-BY:before
{
 content: "\0042";
}

/* Creative Commons Logo */
.cc-logo:before
{
 content: "\0043";
}

.cc-by-nd:before
{
 content: "\0044";
}

.cc-by-nc-eu:before
{
 content: "\0045";
}

.cc-by-nc-nd-eu:before
{
 content: "\0046";
}

.cc-by-nc-sa-eu:before
{
 content: "\0047";
}

.cc-by-nc-jp:before
{
 content: "\004A";
}

.cc-by-nc-nd-jp:before
{
 content: "\004B";
}

.cc-by-nc-sa-jp:before
{
 content: "\004C";
}

.cc-by-nc-sa:before
{
 content: "\004D";
}

.cc-by-nc:before
{
 content: "\004E";
}

.cc-publicdomain:before
{
 content: "\004F";
}

.cc-by-nc-nd:before
{
 content: "\0052";
}

.cc-by-sa:before
{
 content: "\0053";
}

/*===========================
Small Banner
===========================*/

/* Zero with Public Domain */
.cc-ZERO:before,
.cc-zero-public:before
{
 content: "\0030";
}
/* Some Rights Reserved */
.cc-ONE:before,
.cc-SRR:before
{
 content: "\0031";
}
/* CC-BY */
.cc-TWO:before,
.cc-small-by:before
{
 content: "\0032";
}
/* CC-BY-SA */
.cc-THREE:before,
.cc-BY-SA:before
{
 content: "\0033";
}
/* CC-BY-ND */
.cc-FOUR:before,
.cc-BY-SA-ND:before
{
 content: "\0034";
}
/* CC-BY-NC */
.cc-FIVE:before,
.cc-BY-NC:before
{
 content: "\0035";
}
/* CC-BY-NC-SA */
.cc-SIX:before,
.cc-BY-NC-SA:before
{
 content: "\0036";
}
/* CC-BY-NC-ND */
.cc-SEVEN:before,
.cc-BY-NC-ND:before
{
 content: "\0037";
}
/* CC-BLANK */
.cc-EIGHT:before,
.cc-blank:before
{
 content: "\0038";
}
/* Public Domain */
.cc-NINE:before,
.cc-public:before
{
 content: "\0039";
}



.custom-transition(@duration: .2s, @ease: ease-in){
  -webkit-transition: all @duration @ease;
  -moz-transition: all @duration @ease;
  -o-transition: all @duration @ease;
  transition: all @duration @ease;
}

.double-transition(@durationa: .3s, @modea: text-shadow,
                   @durationb: .3s, @modeb: color){
  -webkit-transition: all @durationa @modea, @durationb @modeb;
  -moz-transition: all @durationa @modea, @durationb @modeb;
  -o-transition: all @durationa @modea, @durationb @modeb;
  transition: all @durationa @modea, @durationb @modeb;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   08/02/2014
/// @modified:  09/02/2015
/// @name:      Media Query Max 479px width
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

@media (max-width: 479px){
  .flex-direction-nav .flex-next,
  .flex-direction-nav .flex-prev{
    opacity: .3;
  }

  .navbar-brand,
  .navbar-brand:hover,
  .navbar-brand:active,
  .navbar-brand:focus,
  #main-nav .nav a,
  #main-nav .nav a:active,
  #main-nav .nav a:hover{
    text-shadow: none;
  }

  #main-nav.scrolled .container{
    padding: .8rem;
  }

  #training #subtraining .fade-left{
    text-align: right;
  }

  #training #subtraining .fade-left [class*="brk-"]{
    margin-left: -2rem;
  }

  #training #subtraining li {
    margin-bottom: 1rem;
  }

  #twitter{
    height: auto;
  }

  .coursera-helper{
    padding-left: 1.5rem;
  }

  #training #subtraining .fade-right{
    text-align: left;
  }

  #main-nav.scrolled .navbar-toggle,
  #main-nav .navbar-toggle{
    margin: 0;
    padding-right: 0;
  }

  .fa-custom-icons{
    font-size: 4rem;
  }

  #main-nav .navbar-header .navbar-brand{
    font-size: 2rem;
    line-height: 2rem;
  }

  #main-nav.scrolled .navbar-header .navbar-brand{
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  .navbar-brand .job{
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  .jumbotron .message-box{
    width: 75%;
    margin: 20rem auto 0;
  }

  .jumbotron .right-col p{
    font-size: 2rem;
    line-height: 1.8rem;
  }

  .jumbotron .message-box .fa-4x{
    font-size: 5rem;
  }

  .jumbotron .message-box .fa-3x{
    font-size: 4rem;
  }

  h2.section-title{
    font-size: 3.2rem;
    line-height: 4.6rem;
  }


  p{
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  .btn-theme{
    padding: 1.5rem 4rem;
    font-size: 1.8rem;
  }

  #service-modal .modal-dialog{
    width: 95%;
    padding: .5rem;
    padding-top: 2rem;
  }


  #service-modal h2{
    font-size: 1.5rem;
  }

  #service-modal p{
    padding: .5rem;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
  }

  #service-modal .img_icn{
    font-size: 2rem;
  }

  #about h2{
    font-size: 2.8rem;
    line-height: 3.6rem;
  }

  #about p{
    font-size: 2.1rem;
    line-height: 2.8rem;
  }

  #meetings h2{
    text-align: center;
  }

  #filter-works ul li a{
    font-size: 2.1rem;
    line-height: 2.8rem;
  }

  #project-modal p{
    text-align: left;
  }

  #contact .contact-info p,
  #contact .contact-info address{
    font-size: 1.6rem;
    line-height: 2.6rem;
  }


  #contact .form-control{
    height: 5.8rem;
    font-size: 2.4rem;
    line-height: 3rem;
  }

  #contact .form-group [class*="icon-"]{
    font-size: 3.2rem;
    line-height: 5.8rem;

  }

  #contact textarea.form-control{
    font-size: 2.4rem;
    line-height: 3.2rem;
    padding-top: 1.4rem;
  }

}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   08/02/2014
/// @modified:  09/02/2015
/// @name:      Media Query Max 1199px width
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
@media (max-width: 1199px){

  .jumbotron .left-col p{
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .jumbotron .left-col p span{
    font-size: 3.6rem;
  }

  .jumbotron .right-col p{
    font-size: 5.2rem;
    line-height: 4rem;
  }

}

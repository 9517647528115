///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   31/08/2014
/// @modified:  09/02/2015
/// @name:      Custom overrides & external style changes
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////


// ============================================================================
// Facebook Overrides
// ============================================================================

#fb-root {
  display: none;
}

.fb_iframe_widget, .fb_iframe_widget span,
.fb_iframe_widget span iframe[style] {
  width: 100% !important;
  height: 6rem !important;
}

#u\_0\_0 {
  width: inherit !important;
  height: inherit !important;
}

.fb_iframe_widget {
  color: @fb-color !important;
}

.fb_iframe_widget a {
  color: @fb-anchor-color !important;
  .custom-transition;
}

.fb_iframe_widget a:hover {
  color: @fb-anchor-hover-color !important;
  .custom-transition;
}

// ============================================================================
// Media scrren & print
// ============================================================================

#media-screen{
  display: block;
}

#media-print{
  display: none;
}

.right-icon{
  margin-left: -2rem;
}

// ============================================================================
// Bootstrap 3 responsive columns of same height
// ============================================================================
.container-xs-height{
    display: table;
    padding-left: 0;
    padding-right: 0;
}
.row-xs-height{
    display: table-row;
}
.col-xs-height {
    display: table-cell;
    float: none;
}
@media (min-width: 76.8rem){
    .container-sm-height{
        display: table;
        padding-left: 0;
        padding-right: 0;
    }
    .row-sm-height{
        display: table-row;
    }
    .col-sm-height{
        display: table-cell;
        float: none;
    }
}
@media (min-width: 99.2rem){
    .container-md-height{
        display: table;
        padding-left: 0;
        padding-right: 0;
    }
    .row-md-height{
        display: table-row;
    }
    .col-md-height{
        display: table-cell;
        float: none;
    }
}
@media (min-width: 120rem){
    .container-lg-height{
        display: table;
        padding-left: 0;
        padding-right: 0;
    }
    .row-lg-height{
        display: table-row;
    }
    .col-lg-height{
        display: table-cell;
        float: none;
    }
}

// ============================================================================
// Rotation overrides
// ============================================================================
.rotate-135{
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

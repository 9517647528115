//*****************************************************************************
//*****************************************************************************
//*****************************************************************************
//* jQuery MotionCAPTCHA Plugin CSS
//* josscrowcroft.com/projects/motioncaptcha-jquery-plugin/
//*
//* Only these rules are absolutely necessary for the plugin.
//* NB. The width & height of the canvas can be flexible to fit your
//* layout but there's a recommended min/max
//* - if you need it much bigger/smaller, you could tweak the
//* background-positions of the shape classes.
//*****************************************************************************
//*****************************************************************************
//*****************************************************************************

//*****************************************************************************
//* The canvas:
//*****************************************************************************
#mc-canvas {
  margin-left: 30%;
	width: 220px;  //* For best results, set width to between 210px and 240px
	height: 154px; //* For best results, set height to between 140px and 170px
	background: -9999px -9999px no-repeat;
	background-image: url('/assets/motionCaptcha-red.png') !important;
}

//*****************************************************************************
//* The shapes:
//*****************************************************************************
#mc-canvas.triangle     { background-position:  10px   10px }
#mc-canvas.x            { background-position:-200px   10px }
#mc-canvas.rectangle    { background-position:-400px   10px }
#mc-canvas.circle       { background-position:-600px   10px }
#mc-canvas.check        { background-position:  10px -150px }
#mc-canvas.caret        { background-position:-200px -150px }
#mc-canvas.zigzag       { background-position:-400px -150px }
#mc-canvas.arrow        { background-position:-600px -150px }
#mc-canvas.leftbracket  { background-position:  10px -300px }
#mc-canvas.rightbracket { background-position:-200px -300px }
#mc-canvas.v            { background-position:-400px -300px }
#mc-canvas.delete       { background-position:-600px -300px }
#mc-canvas.leftbrace    { background-position:  10px -450px }
#mc-canvas.rightbrace   { background-position:-200px -450px }
#mc-canvas.star         { background-position:-400px -450px }
#mc-canvas.pigtail      { background-position:-600px -450px }

//*****************************************************************************
//* Disable mouse-selection on the page
//* (only while user is actively drawing on the canvas):
//*****************************************************************************
.mc-noselect {
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   08/02/2014
/// @modified:  09/02/2015
/// @name:      Modal section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

.fade.modal{
  overflow-x: hidden;
}

.modal-backdrop{
  background-color: @modal-background-color;
}

.modal-backdrop.in{
  opacity: 1;
  filter: alpha(opacity=100);
}

.modal.fade .modal-dialog{
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.5s;
}

.modal-content{
  border: none;
  border-radius: 0;
  background-color: @modal-content-background;
}


.modal.in .modal-dialog{
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.modal-dialog{
  width: 90%;
  max-width: 114rem;
  padding-top: 5rem;
}

.modal-header{
  height: 6rem;
  border:none;
  border-bottom: .1rem solid @modal-header-border-bottom;
  margin-bottom: 1rem;
}

.modal-header .close{
  font-size: 4.2rem;
  line-height: 4.2rem;
  margin-top: 0;
  margin-right: 1rem;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  opacity: 1;
  color: @modal-header-close;
}

.modal-header .close:hover{
  color: @modal-header-close-hover;
  opacity: 1;
}

.modal-body .left-col,
.modal-body .right-col{
  float: left;
  padding: 0;
}

.modal-body .left-col{
  width: 50%;
  margin: 0 5rem 2rem 0;
  position: relative;
}

.modal-body .right-col{
  width: 50%;
  padding: 1rem 6rem 0;
}

.modal-body .left-col{
  min-height: 6rem;
  background: transparent;
  z-index: 1;
}

.modal-body h2{
  color: @modal-body-h2-color;
  text-transform: capitalize;
  margin: 2rem 0 2.4rem;
  font-size: 3.6rem;
}

.modal-body .project-desc{
  color: pink;
  margin: 0 0 2.4rem;
  text-align: justify;

}

.modal-body .btn-theme{
  margin-top: 4.8rem;
}

.modal-body .loader{
  position:absolute;
  left: 50%;
  top: 0;
  margin-top: 27%;
  margin-left: -1rem;
  width: 3rem;
  height: 3rem;
  background: url('../assets/loader.gif') no-repeat 50% 50%;
}

.modal-body .image-wrapper{
  position: absolute;
  width: 96%;
  top: 4%;
  left: 3%;
  border: none;
  z-index: -1;
  opacity: 0;
  .custom-transition(.5s, ease-out);
}

.modal-body .image-wrapper.done{
  opacity: 1;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   20/08/2014
/// @modified:  15/11/2016
/// @name:      Ruby Red Style
/// @version:   1.9.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

// ============================================================================
// Vendor imports
// ============================================================================

@import url('vendor/bootstrap.less');    // Bootstrap 3.3.6
@import url('vendor/font-awesome.less');   // FontAwesome 4.5.0
@import url('vendor/cc-icons.less'); //CC Icons 1.2.1
@import url('vendor/flexslider.less');   // jQuery FlexSlider v2.0
@import url('vendor/motioncaptcha.less');


@import url('vendor/brk-fonts.less'); //Brk Fonts 1.0.0
@import url('vendor/class-declare.less');
@import url('vendor/extras.less');
@import url('vendor/animate.less');
@import url('vendor/codes.less');

// ============================================================================
// Color scheme
// ============================================================================

@import url('ruby-red-colors.less');

// ============================================================================
// Custom Mixins
// ============================================================================

@import url('mixins.less');


// ============================================================================
// Custom buttons
// ============================================================================

@import url('buttons.less');

// ============================================================================
// Custom overrides
// ============================================================================

@import url('overrides.less');

// ============================================================================
// Typographies
// ============================================================================

@import url('typographies.less');


// ============================================================================
// Navigation
// ============================================================================

@import url('navigation.less');


// ============================================================================
// Header
// ============================================================================

@import url('header.less');


// ============================================================================
// Services section
// ============================================================================

@import url('services.less');


// ============================================================================
// About section
// ============================================================================

@import url('about.less');


// ============================================================================
// Skills section
// ============================================================================

@import url('skills.less');


// ============================================================================
// Training section
// ============================================================================

@import url('training.less');


// ============================================================================
// Unofficial training section
// ============================================================================

@import url('subtraining.less');


// ============================================================================
// Portfolio section
// ============================================================================

@import url('portfolio.less');


// ============================================================================
// Modals
// ============================================================================

@import url('modal.less');


// ============================================================================
// Twitter
// ============================================================================

@import url('twitter.less');


// ============================================================================
// Contact
// ============================================================================

@import url('contact.less');


// ============================================================================
// Footer
// ============================================================================

@import url('footer.less');


// ============================================================================
// Scrolling animations
// ============================================================================

@import url('scrolls.less');

//*****************************************************************************
//** General
//*****************************************************************************

.highlight{
  color: @main-color;
}

.img-center{
  margin: 20px auto;
}

body {
  color: @main-color-font;
  overflow-x: hidden;
}


// ============================================================================
// Media queries
// ============================================================================

@import url('media-ridiculously-large.less'); // 2060px width media query
@import url('media-ultra-large.less'); // 1199px width media query
@import url('media-super-large.less'); // 1025px width media query
@import url('media-large.less'); // 991px width media query
@import url('media-mid.less'); // 768px width media query
@import url('media-min.less'); // 479px width media query

/*/////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   31/08/2014
/// @modified:  21/09/2014
/// @name:      Brk Fonts
/// @version:   1.0.0 – Altamira Oriole
/// @site:      http://brkfonts.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////*/


@font-face {
  font-family: 'brkfont';
  src: url('/fonts/brk-font.eot');
  src: url('/fonts/brk-font.eot?#iefix') format('embedded-opentype'),
       url('/fonts/brk-font.woff') format('woff'),
       url('/fonts/brk-font.ttf') format('truetype'),
       url('/fonts/brk-font.svg?#brkfonts') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Chrome hack: SVG is rendered more smooth in Windozze. 100% magic,
// uncomment if you need it.
//
// Note, that will break hinting! In other OS-es font will be not as sharp
// as it could be

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'brkfonts';
    src: url('../font/brkfonts.svg?35488543#brkfonts') format('svg');
  }
}
*/



///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   09/02/2014
/// @modified:  09/02/2015
/// @name:      Typographies
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////


@font-face{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local('Raleway Light'), local('Raleway-Light'), url(//fonts.gstatic.com/s/raleway/v8/-_Ctzj9b56b8RgXW8FArib3hpw3pgy2gAi-Ip7WPMi0.woff) format('woff');
}
@font-face{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), url(//fonts.gstatic.com/s/raleway/v8/cIFypx4yrWPDz3zOxk7hIQLUuEpTyoUstqEm5AMlJo4.woff) format('woff');
}

@font-face{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(//themes.googleusercontent.com/static/fonts/lato/v7/kcf5uOXucLcbFOydGU24WALUuEpTyoUstqEm5AMlJo4.woff) format('woff');
}
@font-face{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(//themes.googleusercontent.com/static/fonts/lato/v7/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff) format('woff');
}
@font-face{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(//themes.googleusercontent.com/static/fonts/lato/v7/qdgUG4U09HnJwhYI-uK18wLUuEpTyoUstqEm5AMlJo4.woff) format('woff');
}
@font-face{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'), url(//themes.googleusercontent.com/static/fonts/lato/v7/G2uphNnNqGFMHLRsO_72ngLUuEpTyoUstqEm5AMlJo4.woff) format('woff');
}
@font-face{
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local('Lato Light Italic'), local('Lato-LightItalic'), url(//themes.googleusercontent.com/static/fonts/lato/v7/2HG_tEPiQ4Z6795cGfdivLO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
}
@font-face{
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local('Lato Italic'), local('Lato-Italic'), url(//themes.googleusercontent.com/static/fonts/lato/v7/RYyZNoeFgb0l7W3Vu1aSWOvvDin1pK8aKteLpeZ5c0A.woff) format('woff');
}
@font-face{
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url(//themes.googleusercontent.com/static/fonts/lato/v7/HkF_qI1x_noxlxhrhMQYELO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
}

@font-face{
  font-family: 'Amaranth';
  font-style: normal;
  font-weight: 400;
  src: local('Amaranth'), url(//themes.googleusercontent.com/static/fonts/amaranth/v4/RvdVaxHTnYbJDko8dLi4znYhjbSpvc47ee6xR_80Hnw.woff) format('woff');
}
@font-face{
  font-family: 'Amaranth';
  font-style: normal;
  font-weight: 700;
  src: local('Amaranth Bold'), local('Amaranth-Bold'), url(//themes.googleusercontent.com/static/fonts/amaranth/v4/hh4Od9pccylRpuf5LUPgErrIa-7acMAeDBVuclsi6Gc.woff) format('woff');
}
@font-face{
  font-family: 'Amaranth';
  font-style: italic;
  font-weight: 400;
  src: local('Amaranth Italic'), local('Amaranth-Italic'), url(//themes.googleusercontent.com/static/fonts/amaranth/v4/J0IatWFySU8P-ir89pUoJL3hpw3pgy2gAi-Ip7WPMi0.woff) format('woff');
}
@font-face{
  font-family: 'Amaranth';
  font-style: italic;
  font-weight: 700;
  src: local('Amaranth Bold Italic'), local('Amaranth-BoldItalic'), url(//themes.googleusercontent.com/static/fonts/amaranth/v4/BHyuYFj9nqLFNvOvGh0xT3bFhgvWbfSbdVg11QabG8w.woff) format('woff');
}

body{
  font-family: 'Raleway', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-family: Amaranth, sans-serif;
  color:@main-color;
}

#home,
#main-nav{
  font-family: Amaranth, sans-serif;
}

h1, h2{
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 400;
  letter-spacing: .02em;
}

h2.section-title{
  position: relative;
  margin: 0 0 9rem;
  font-size: 4.8rem;
  line-height: 6.4rem;
  font-weight: 400;
  text-align: center;
  z-index: 1;
}

.section-title span{
  font-size: 150%;
  font-weight: 400;
}

.section-title [class*=fa]{
  position: absolute;
  top: -28px;
  left: 50%;
  margin-left: -60px;
  font-size: 120px;
  z-index: -1;
}

h2{
  font-size: 21px;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

p{
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
}

a,
a:hover,
a:focus{
  outline: none !important;
  color: @main-anchor-hover;
  text-decoration: none;
}

a{
  color: @main-anchor;
  text-decoration: none;
}


a:focus,
.btn:focus{
  outline: none;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   08/02/2014
/// @modified:  09/02/2015
/// @name:      Media Query Max 1199px width
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
@media (min-width: 2060px) {
  .container {
    width: 2000px;
  }

  .coursera-helper {
    padding-left: 0;
    padding-right: 4rem;
  }

  .jumbotron .message-box{
    width: 65%;
  }

  .jumbotron p{
    display: block !important;
  }
}

.brk-postgresql:before {
   content: '\e800';
 }
.brk-valladolid:before {
   content: '\e801';
 }
.brk-wordpress-square:before {
   content: '\e802';
 }
.brk-python-square:before {
   content: '\e803';
 }
.brk-python:before {
   content: '\e804';
 }
.brk-mirlodev-square:before {
   content: '\e805';
 }
.brk-mirlodev:before {
   content: '\e806';
 }
.brk-valladolid-square:before {
   content: '\e807';
 }
.brk-miriadax-square:before {
   content: '\e808';
 }
.brk-juvecyl-square:before {
   content: '\e809';
 }
.brk-juvecyl:before {
   content: '\e80a';
 }
.brk-miriadax:before {
   content: '\e80b';
 }
.brk-gitlab-square:before {
   content: '\e80c';
 }
.brk-opendraft-square:before {
   content: '\e80d';
 }
.brk-opendraft:before {
   content: '\e80e';
 }
.brk-gitlab:before {
   content: '\e80f';
 }
.brk-cyl-square:before {
   content: '\e810';
 }
.brk-jquery-square:before {
   content: '\e811';
 }
.brk-jquery:before {
   content: '\e812';
 }
.brk-cyl:before {
   content: '\e813';
 }
.brk-rails-square:before {
   content: '\e814';
 }
.brk-postgresql-1:before {
   content: '\e815';
 }
.brk-postgresql-square:before {
   content: '\e816';
 }
.brk-rails:before {
   content: '\e817';
 }
.brk-wordpress:before {
   content: '\e818';
 }
.brk-upvx-square:before {
   content: '\e819';
 }
.brk-upvx:before {
   content: '\e81a';
 }
.brk-coffee-square:before {
   content: '\e81b';
 }
.brk-coffee:before {
   content: '\e81c';
 }
.brk-jquery-ui:before {
   content: '\e81d';
 }
.brk-iebs-square:before {
   content: '\e81e';
 }
.brk-django-square:before {
   content: '\e81f';
 }
.brk-django:before {
   content: '\e820';
 }
.brk-iebs:before {
   content: '\e821';
 }
.brk-pyramid-square:before {
   content: '\e822';
 }
.brk-java-square:before {
   content: '\e823';
 }
.brk-java:before {
   content: '\e824';
 }
.brk-pyramid:before {
   content: '\e825';
 }
.brk-cisco-square:before {
   content: '\e826';
 }
.brk-cisco:before {
   content: '\e827';
 }
.brk-ruby:before {
   content: '\e828';
 }
.brk-ruby-square:before {
   content: '\e829';
 }

[class^="brk-"]:before, [class*=" brk-"]:before {
  font-family: "brkfont";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  font-size: 150%;
  margin-right: .2em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes */
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  margin-left: .2em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brk-fa.narrow {
  margin-right: 0;
  margin-left: -1rem;
}

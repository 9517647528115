///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   31/08/2014
/// @modified:  09/02/2015
/// @name:      Custom buttons
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

// ============================================================================
// Main button style
// ============================================================================

.btn-theme {
  background-color: @theme-button-main-background;
  color: @theme-button-main-color;
  padding: 1.5rem 9rem;
  font-size: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  -webkit-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}

.btn-theme:hover,
.btn-theme:focus {
  cursor: pointer;
  color: @theme-button-main-color-hover;
  background-color: @theme-button-main-background-hover;
  border: .1rem solid  @theme-button-main-border-hover;
}

.btn-theme [class*=fa] {
  margin-right: 1rem;
}

// ============================================================================
// Alternative button style
// ============================================================================

.btn-theme.alternative {
  background-color: transparent;
  padding: 1.4rem 3.5rem;
  border: .2rem solid @theme-button-alternative-border;
  font-weight: 400;
  -webkit-transition: all 0.3s ease-out;
  transition:all 0.3s ease-out;
}

.btn-theme.alternative:hover,
.btn-theme.alternative:focus,
.btn-theme.alternative:active {
  color: @theme-button-alternative-color-hover !important;
  background-color: @theme-button-alternative-background-hover;
  border: .2rem solid @theme-button-alternative-border-hover;
  opacity: 1;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   31/08/2014
/// @modified:  09/02/2015
/// @name:      Header section with jumbotron
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

.jumbotron {
  background: transparent;
  color: @main-color;
}

header.jumbotron{
  padding: 0;
  margin-bottom: 0;
  min-height: 4rem;
}

.jumbotron .message-box {
  width: 90%;
  margin: 20rem auto 0;
  background: @jumbotron-background;
  color: @jumbotron-color;
  padding: 2rem 0;
  height: 55rem;
  border-radius: 2rem;
}

.home-slider {
  z-index: 1 !important;
}

.jumbotron .left-col {
  width: 35%;
  text-align: right;
}

.jumbotron .right-col {
  width: 100%;
  text-align: center;
  overflow: hidden;
  border: none;
  box-shadow: none;
  background: transparent;
  margin: 0;
  padding: 0;
}

.jumbotron .left-col p {
  display: inline-block;
  margin: 0;
  padding: 1.8rem .5rem 1.2rem 3rem;
  font-size: 3.8rem;
  font-weight: 400;
  line-height: 4.8rem;
}

.jumbotron .left-col p span {
  font-size: 4.2rem;
  font-weight: 400;
}

.jumbotron .right-col p {
  display: inline-block;
  margin: 0;
  padding: 1rem 3rem 1rem .5rem;
  font-size: 6rem;
  line-height: 10rem;
  font-weight: 700;
}

.jumbotron .home-slider .slides li {
  opacity: 0;
  -webkit-ransition: opacity 0.4s ease-out;
  transition: opacity 0.4s ease-out;
}

.no-csstransitions .jumbotron .home-slider .slides li {
  opacity: 1;
}

.jumbotron .home-slider .slides li.flex-active-slide,
.jumbotron .home-slider .slides li.clone {
  opacity: 1;
}

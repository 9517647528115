///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   08/02/2014
/// @modified:  09/02/2015
/// @name:      Media Query Max 991px width
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

@media (max-width: 991px) {

  .navbar-header{
    width: 100%;
  }
  .navbar-header .navbar-brand{
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  #site-nav ul{
    float: none !important;
    margin-right: 0 !important;
  }

  #main-nav .nav a{
    color: @nav-anchor-color-large;
  }

  #main-nav .nav a:before{
    font-size: 3rem;
    color: @top-nav-indicators-large;
  }

  #main-nav.scrolled .nav a:before{
    color: @top-nav-indicators;
  }

  .navbar-brand .job{
    text-align: right;
    font-size: 2rem;
    line-height: 2rem;
    display: block;
    float: right;
  }

  .navbar-brand br{
    display: none;
  }

  .navbar-brand{
    display: inline-block;
    width: 100%;
  }

  #twitter{
    height: 40rem;
  }

  .contact-box{
    padding-right: 1rem;
  }

  ul.socials{
    -webkit-padding-start: 0;
  }

  .jumbotron .message-box{
    padding: 1rem 0;
    width: 75%;
    height: 20rem;
  }

  .coursera-helper{
    padding-left: 0;
  }

  .margin-left{
    margin-left: 0;
  }

  .jumbotron .left-col{
    width: 100%;
    text-align: center;
  }

  .jumbotron .right-col{
    width: 100%;
    text-align: center;
  }

  .jumbotron .right-col p{
    padding: 1rem;
  }

  .jumbotron .left-col p span{
    font-size: 3.2rem;
  }

  .jumbotron .right-col p{
    font-size: 2.2rem;
    line-height: 2rem;
  }

}

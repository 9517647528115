///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   31/02/2014
/// @modified:  09/02/2015
/// @name:      Scroll animations
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

.scrollimation.fade-in{
  opacity: 0;
  .custom-transition(.4s, ease-out);
}

.scrollimation.fade-in.in{
  opacity: 1;
}

.scrollimation.scale-in{
  opacity: 0;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: -webkit-transform .6s cubic-bezier(.17,.89,.67,1.57), opacity .6s ease-out;
  transition: transform .6s cubic-bezier(.17,.89,.67,1.57), opacity .6s ease-out;
}

.scrollimation.scale-in.in{
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.scrollimation.fade-right{
  opacity: 0;
  -webkit-transform: translateX(-3rem);
  -moz-transform: translateX(-3rem);
  -ms-transform: translateX(-3rem);
  transform: translateX(-3rem);
  -webkit-transition: -webkit-transform .6s ease-in,opacity .8s ease-in;
  transition: transform .6s ease-in,opacity .8s ease-in;
}

.scrollimation.fade-right.in{
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.scrollimation.fade-left{
  opacity: 0;
  -webkit-transform: translateX(3rem);
  -moz-transform: translateX(3rem);
  -ms-transform: translateX(3rem);
  transform: translateX(3rem);
  -webkit-transition: -webkit-transform .6s ease-in,opacity .8s ease-in;
  transition: transform .6s ease-in,opacity .8s ease-in;
}

.scrollimation.fade-left.in{
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.scrollimation.fade-up{
  opacity: 0;
  -webkit-transform: translateY(3rem);
  -moz-transform: translateY(3rem);
  -ms-transform: translateY(3rem);
  transform: translateY(3rem);
  -webkit-transition: -webkit-transform .6s ease-in,opacity .8s ease-in;
  transition: transform .5s ease-in,opacity .8s ease-in;
}

.scrollimation.fade-up.in{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.touch .scrollimation.fade-in{
  opacity: 1;
}

.touch .scrollimation.scale-in{
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.touch .scrollimation.fade-right{
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.touch .scrollimation.fade-left{
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.touch .scrollimation.fade-up{
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   31/08/2014
/// @modified:  09/02/2015
/// @name:      About section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

#about {
  background: @about-background;
  color: @about-color;
  padding: 8rem 0;
}

#about h2 {
  color: @about-h2-color;
  font-size: 3.6rem;
  line-height: 4.2rem;
  font-weight: 400;
  margin-bottom: 2.4rem;
  position: relative;
}

#about p {
  font-size: 2.4rem;
  line-height: 3.6rem;
  font-weight: 400;
  margin-bottom: 4.2rem;
}

#about a {
  color: @about-anchor;
  .custom-transition;
}

#about a:hover {
  color: @about-anchor-hover;
  .custom-transition;
}

#about .btn-theme.transparent {
  font-size: 1.8rem;
  text-decoration: none;
  .custom-transition;
}

#about img {
  box-shadow: 1.2rem 0 0 1.2rem @about-img-shadow,
    -1.2rem 0 0 1.2rem @about-img-shadow;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   31/08/2014
/// @modified:  09/02/2015
/// @name:      Training section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

#training{
  background: @training-background-color;
  color: @training-color;
  padding: 8rem 0 0 0;
  text-align: justify;
}

#training-header{
  padding-top: 3rem;
  margin-bottom: 4rem;
  background: @training-header-background;
  border-top: .1rem solid @training-header-background;
  border-bottom: .1rem solid @training-header-background;
}

#training .section-title [class*=fa]{
  color: @training-icon-color;
}

#training h2.section-title{
  color: @training-h2-color;
}

#training ul{
  list-style: none;
  font-size: 1.7rem;
  padding-left: .2rem;
}


///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   09/02/2014
/// @modified:  09/02/2015
/// @name:      Services section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

#services-header{
  padding-top: 3rem;
  margin-bottom: 4rem;
  background: @services-header-background;
  border-top: .1rem solid @services-header-background;
  border-bottom: .1rem solid @services-header-background;
}

#services .section-title [class*=fa]{
  color: @services-icon-color;
}

#services h2.section-title{
  color: @services-h2-color;
}

#services{
  background: @services-background;
  color: @services-color;
  padding: 0 0 5rem 0;
  text-align: justify;
}

#services .row.line, #training .row.line{
  height: .6rem;
  background: @services-divider-lines;
  margin-bottom: 2.4rem;
}

#services .line .spot, #training .line .spot{
  margin: -.7rem auto;
  display: block;
  width: 2rem;
  height: 2rem;
  background: @services-divider-points;
  border: .5rem solid @services-divider-points-border;
  border-radius: 50%;
}

.services .icon{
  position: relative;
  display: inline-block;
  padding: 0;
  margin-bottom: 2.4rem;
  width: 16rem;
  height: 16rem;
  text-align: center;
  font-size: 7.2rem;
  line-height: 13rem;
  color: @services-icon-color;
  background: none;
  border: 1rem solid @services-icon-border;
  border-radius: 50%;
  z-index: 1;
  .custom-transition;
}

.services .icon:hover{
  color: @services-icon-color-hover;
}

.services .icon:after{
  pointer-events: none;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: @services-icon-background;
  border-radius: 50%;
  border: .2rem solid @services-icon-background;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  top: -.2rem;
  left: -.2rem;
  z-index: -1;
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  opacity: 0;
  .custom-transition(.4s, ease-out);
}

.services .icon:hover:after{
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  .custom-transition(.4s, ease-out);
}

.services .item{
  margin-bottom: 8rem;
}

.services h2{
  margin: 0;
  margin-bottom: 2.4rem;
  font-size: 2.4rem;
  font-weight: 400;
}

.services p{
  margin: 0;
  margin-bottom: 2.4rem;
}


.services.bottom-row p{
  margin-top: 4.8rem;
}

#service-modal p{
  text-align: justify;
  padding: 1.5rem;
}

#service-modal h2{
  font-size: 3rem;
  line-height: 1rem;
  text-align: center;
}

.fa-custom-icons{
  font-size: 10rem;
  color: @services-fa-custom-icons;
  text-align: center;
}

.min-margin{
  margin: 2rem !important;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   09/02/2014
/// @modified:  09/02/2015
/// @name:      Twitter section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

#twitter{
  color: @twitter-color;
  background: @twitter-background;
  padding: 6rem 0;
  height: 32rem;
}

#twitter a:hover,
#twitter a:active,
#twitter a:focus{
  text-decoration: none !important;
  color: @twitter-anchor-hover;
  .custom-transition;
}

#twitter a{
  color: @twitter-anchor;
  .custom-transition;
}

#twitter i[class*="fa"],
#twitter .timePosted{
  color: @twitter-secondary-color;
}

#twitter h2{
  color: @twitter-header-h2;
  position: relative;
}

#twitter .tweet{
  font-size: 2rem;
  line-height: 3.6rem;
  font-weight: 400;
  word-spacing: .5rem;
  margin-bottom: 5rem;
}

#twitter .tweet a:hover{
  text-decoration: underline;
}

#twitter .timePosted{
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

#twitter .interact{
  margin-left: 2rem;
  padding-right: 8rem;
  display: inline-block;
  font-size: 2.1rem;
  font-weight: 400;
}

#twitter .interact a{
  margin-right: .6rem;
}

#twitter .twitter-icon{
  padding-top: .6rem;
  text-align: center;
}

#twitter .fa-twitter{
  font-size: 6rem;
}

#twitter-slider{
  border: none;
  box-shadow: none;
  background: transparent;
  margin: 0;
  padding: 0;
}

#twitter-slider [data-scribe="element:verified_badge"] {
  position: absolute;
  right: 0;
  top: 0;
}

#twitter-slider [data-scribe="element:screen_name"] {
  top: 0;
  position: absolute;
  right: 1.3rem;
}

#twitter-slider img {
  width: auto;
  display: inline-block;
  border-radius: 50%;
}

#twitter-slider .flex-direction-nav{
  display: block;
  position: absolute;
  bottom: 50%;
  right: 0;
}

#twitter-slider .flex-direction-nav li{
  display: inline;
}

#twitter .flex-direction-nav .flex-prev,
#twitter .flex-direction-nav .flex-next{
  background: transparent;
  background-image: none;
  display: inline;
  opacity: 1;
  text-indent: 0;
  position: relative;
  left: 0;
  top: 0;
  width: auto;
  height: 4.2rem;
  padding: 0;
  margin: 0;
  margin-left: .7rem;
  font-size: 4.2rem;
  line-height: 4.2rem;
  color: @twitter-direction-color;
}

#twitter-slider .flex-direction-nav a:hover,
#twitter-slider .flex-direction-nav a:active,
#twitter-slider .flex-direction-nav a:focus{
  text-decoration: none;
}

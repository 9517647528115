///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   31/08/2014
/// @modified:  09/02/2015
/// @name:      Navigation style
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

.navbar{
  margin:0;
  border-radius:0;
}

#main-nav .navbar-toggle{
  border: 0;
}

.navbar-brand,
.navbar-brand:hover,
.navbar-brand:active,
.navbar-brand:focus{
  padding: 0 1rem;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: normal;
  color: @top-nav-color;
  text-shadow: .1rem 0 1.1rem @top-nav-shadow;
  .custom-transition(.3s, ease-out);
}

#main-nav.scrolled .navbar-brand,
#main-nav.scrolled .navbar-brand:hover,
#main-nav.scrolled .navbar-brand:active,
#main-nav.scrolled .navbar-brand:focus{
  text-shadow: none;
}

.navbar-brand:hover{
  color: @top-nav-color-hover;
}

.navbar-brand .job{
  font-size: 2.4rem;
  font-weight: 400;
}

#main-nav.scrolled .navbar-brand{
  color: @top-nav-color-scrolled;
}

#main-nav.scrolled .navbar-brand:hover{
  color: @top-nav-color-hover-scrolled;
}

#main-nav{
  background: transparent;
  color: @top-nav-border;
  box-shadow: 0 0 0 transparent;
  .custom-transition(.3s, ease-out);
}

#main-nav.scrolled{
  background: @top-nav-background;
  border-bottom: .1rem solid @top-nav-border;
}

#main-nav.scrolled .navbar-toggle{
  color: @collapsed-button-color;
}

#main-nav .container{
  padding: 3rem 2rem;
  -webkit-transition: padding .5s ease-out;
  transition: padding .5s ease-out;
}

#main-nav.scrolled .container{
  padding: 3rem 2rem;
}

#main-nav .nav a,
#main-nav .nav a:active,
#main-nav .nav a:hover{
  padding: 0 1rem;
  font-size: 1.8rem;
  line-height: 5.4rem;
  font-weight: 400;
  background: transparent;
  color: @nav-anchor-color;
  text-shadow: .1rem 0 1.1rem @top-nav-shadow;
}

.at-follow-label{
  color: @addthis-follow-color;
}


#main-nav.scrolled .nav a,
#main-nav.scrolled .nav a:active,
#main-nav.scrolled .nav a:hover{
  color: @nav-anchor-color-scrolled;
  text-shadow: none;
}


#main-nav li{
  background: transparent;
  margin: 0 1rem;
  border: .2rem solid transparent;
}

#main-nav .dropdown-menu a{
  font-size: 1.4rem;
}

/* Hover Effect */
#main-nav .nav a{
  position: relative;
}

#main-nav .nav a:before{
  position: absolute;
  bottom: -.5rem;
  left: 50%;
  color: @top-nav-indicators;
  content: '•';
  text-shadow: 0 0 transparent;
  font-size: 1.6rem;
  line-height: 2.1rem;
  .double-transition;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
}

#main-nav .nav a:hover:before,
#main-nav .nav li.active a:before{
  color: @nav-anchor-bullet-color;
  text-shadow: 1.2rem 0 @nav-anchor-bullet-color, -1.2rem 0 @nav-anchor-bullet-color;
}

#main-nav.scrolled .nav a:hover:before,
#main-nav.scrolled .nav li.active a:before{
  color: @nav-anchor-bullet-color-scrolled;
  text-shadow: 1.2rem 0 @nav-anchor-bullet-color-scrolled, -1.2rem 0 @nav-anchor-bullet-color-scrolled;
}

.no-textshadow #main-nav .nav a:before{
  content: '• • •';
}

.no-csstransforms #main-nav .nav a:before{
  margin-left: -1.5rem;
}

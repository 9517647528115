///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   09/02/2014
/// @modified:  31/08/2014
/// @name:      Portfolio section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

#portfolio-header{
  padding-top: 3rem;
  margin-bottom: 4rem;
  padding-bottom: 2rem;
  background: @portfolio-header-background;
  border-top: .1rem solid @portfolio-header-background;
  border-bottom: .1rem solid @portfolio-header-background;
}

#portfolio .section-title [class*=fa]{
  color: @portfolio-icon-color;
}

#portfolio h2.section-title{
  color: @portfolio-h2-color;
}

#portfolio{
  background: @portfolio-background;
  color: @portfolio-color;
  padding: 0 0 5rem 0;
  text-align: justify;
}

#portfolio .section-title{
  font-weight: 400;
  margin-bottom: 6rem;
}


#filter-works ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

#filter-works ul li{
  display: inline-block;
  padding: 0;
  line-height: 2.4rem;
  background: transparent;
  margin: 0 0 0 1rem;
}

#filter-works ul li:after{
  content: "/";
  margin-left: 1rem;
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 400;
  color: @portfolio-filter-ul-color;
}

#filter-works ul li:last-child:after{
  display: none;
}

#filter-works ul li a,
#filter-works ul li a:active,
#filter-works ul li a:hover{
  padding: 0;
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: 400;
  background: transparent;
  color: @portfolio-filter-ul-color;
  text-transform: uppercase;
  text-decoration: none;
  .custom-transition(.3s, ease-out);
}

#filter-works ul li:hover a,
#filter-works ul li.active a{
  color: @portfolio-filter-ul-color-hover;
}

.masonry-wrapper{
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}

#projects-container{
  margin: 0 auto;
  visibility: hidden;
}

#projects-container .project-item{
  display: block;
  position: relative;
  margin: 0;
  width: 35rem;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  filter: alpha(opacity=100);
  overflow: hidden;
}

#projects-container .project-item.filtered{
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  filter: alpha(opacity=0);
}

.project-item img{
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  .custom-transition(.3s, ease-out);
}

.project-item:hover img{
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}


.project-item .hover-mask{
  position: absolute;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: @portfolio-hover-mask;
  opacity: 0;
  filter: alpha(opacity=0);
  .custom-transition(.3s, ease-out);
}

.project-item:hover .hover-mask{
  opacity: 1;
  filter: alpha(opacity=100);
  padding: 2rem;
}

.no-csstransitions .project-item:hover .hover-mask{
  opacity: 0;
  filter: alpha(opacity=0);
}

.project-item .hover-mask:after{
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  border: 0 solid @portfolio-color-hover-mask-after;
  .custom-transition(.3s, ease-out);
}

.project-item .hover-mask:hover:after{
  border-width: 2rem;
}


.project-item .hover-mask .project-title{
  color: @portfolio-color-hover-mask-title;
  margin: 0;
  padding: 1.6rem;
  font-size: 2.1rem;
  line-height: 2.4rem;
  font-weight: 400;
}

.project-item .hover-mask p {
  color: @portfolio-color-p;
  margin: 0;
  padding: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  .custom-transition(.3s, ease-out);
}

.project-item .hover-mask:hover p{
  bottom: 2rem;
  right: 2rem;
}

.no-opacity .project-item .hover-mask p{
  visibility: hidden;
}

.no-opacity .project-item .hover-mask:hover p{
  visibility: visible;
}

.masonry,
.masonry .masonry-brick{
  -webkit-transition-duration: 0.7s;
  -moz-transition-duration: 0.7s;
  -ms-transition-duration: 0.7s;
  -o-transition-duration: 0.7s;
  transition-duration: 0.7s;
  transition-timing-function:ease-in-out;
}

.masonry{
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.masonry .masonry-brick{
  -webkit-transition-property: left, right, top, -webkit-transform, opacity;
  -moz-transition-property: left, right, top, -moz-transform, opacity;
  -ms-transition-property: left, right, top, -ms-transform, opacity;
  -o-transition-property: left, right, top, -o-transform, opacity;
  transition-property: left, right, top, transform, opacity;
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
///
/// @author:    José Antonio Yáñez Jiménez
/// @created:   09/02/2014
/// @modified:  09/02/2015
/// @name:      Unofficial training section
/// @version:   1.8.0
/// @site:      www.jimenezfrontend.es
///
///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

#subtraining-header{
  padding-top: 3rem;
  margin-bottom: 4rem;
  background: @subtraining-header-background;
}

#training #subtraining-header .section-title [class*=fa] {
  font-size: 8rem;
  margin-left: -3rem;
  color: @subtraining-title-icon;
}

#subtraining h3{
  color: @subtraining-header-h3;
  text-align: center;
  padding-bottom: 4rem;
}

#subtraining {
  background-color: @subtraining-background-color;
  color: @subtraining-color;
  padding-bottom: 14rem;
}

#subtraining a{
  color: @subtraining-anchor;
  transition: color 0.2s ease-in;
}

#subtraining a:hover{
  color: @subtraining-anchor-hover;
}

#subtraining [class*=fa],
#subtraining [class*=brk]{
  color: @subtraining-icon-color;
}

.coursera-helper{
  font-size: 5rem;
  text-align: center;
  display: block;
  padding-left: 3rem;
}
